<template>
  <div v-if="formModel" class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile/user_info">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.address") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Sys_form">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="blur"
          @submit.prevent="handleConfirm"
          autocomplete="off"
        >
          <h3>{{ t("profile.label.realInfo") }}</h3>
          <div class="box">
            <ul>
              <li>{{ t("profile.label.actualName") }}</li>
              <li>
                <AFormItem
                  name="receiptName"
                  v-bind="validateInfos.receiptName"
                >
                  <AInput
                    v-model:value="formModel.receiptName"
                    :placeholder="t('profile.placeholder.realName')"
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.actualCellNo") }}</li>
              <li>
                <AFormItem
                  name="phoneNumber"
                  v-bind="validateInfos.phoneNumber"
                >
                  <AInput
                    :maxlength="15"
                    v-model:value="formModel.phoneNumber"
                    :placeholder="t('profile.placeholder.contactPhone')"
                  />
                </AFormItem>
              </li>
            </ul>
          </div>
          <h3>{{ t("profile.label.addressInfo") }}</h3>
          <div class="box">
            <ul>
              <li>{{ t("profile.label.addressArea") }}</li>
              <li>
                <AFormItem
                  name="receiptArea"
                  v-bind="validateInfos.receiptArea"
                >
                  <AInput
                    v-model:value="formModel.receiptArea"
                    :placeholder="t('profile.placeholder.area')"
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.address") }}</li>
              <li>
                <AFormItem
                  name="receiptAddress"
                  v-bind="validateInfos.receiptAddress"
                >
                  <AInput
                    v-model:value="formModel.receiptAddress"
                    :placeholder="t('profile.placeholder.address')"
                  />
                </AFormItem>
              </li>
            </ul>
          </div>
          <div class="Sys_submit_btn address Sys_submit_btn_b">
            <button :disabled="!validateStatus" type="submit">
              {{ t("common.button.confirmModify") }}
            </button>
          </div>
        </AForm>
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
</template>

<script>
import { ref, unref, reactive, watch, computed, onMounted } from "vue";
import { Form, message } from "ant-design-vue";
import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
const useForm = Form.useForm;
import { useI18n } from "@/lang";
import { RE_PHONE_NUMBER } from "@/utils/constant";
import api from "@/api";
import { formatServerMsg } from '@/utils/formatter'
export default {
  components: { Loading },
  setup() {
    const { t } = useI18n();
    let loading = ref(false);
    const router = useRouter();
    const formRef = ref(null);
    const formModel = reactive({
      receiptName: null,
      receiptArea: null,
      phoneNumber: null,
      receiptAddress: null,
    });
    let userDeliveryAddress = ref(null);

    const back = computed(() => {
      const {
        query: { back },
      } = unref(router.currentRoute);
      return back;
    });

    const ruleRef = reactive({
      receiptName: [
        {
          required: true,
          message: t("profile.placeholder.realName"),
        },
      ],
      receiptArea: [
        {
          required: true,
          message: t("profile.placeholder.area"),
        },
      ],
      phoneNumber: [
        {
          required: true,
          message: t("profile.placeholder.validPhoneNumber"),
        },
        {
          validator: (rule, value) => {
            return RE_PHONE_NUMBER.test(value)
              ? Promise.resolve()
              : Promise.reject(t("profile.placeholder.validPhoneNumber"));
          },
        },
      ],
      receiptAddress: [
        {
          required: true,
          message: t("profile.placeholder.address"),
        },
      ],
    });

    const { validateInfos } = useForm(formModel, ruleRef);

    const validateStatus = computed(() => {
      if (
        validateInfos.receiptName?.validateStatus === "success" &&
        validateInfos.receiptArea?.validateStatus === "success" &&
        validateInfos.phoneNumber?.validateStatus === "success" &&
        validateInfos.receiptAddress?.validateStatus === "success"
      ) {
        return true;
      } else {
        return false;
      }
    });

    function refreshAddress() {
      console.log(userDeliveryAddress);
      if (userDeliveryAddress && userDeliveryAddress.value) {
        formModel.receiptName = userDeliveryAddress.value.receiptName;
        formModel.receiptArea = userDeliveryAddress.value.receiptArea;
        formModel.phoneNumber = userDeliveryAddress.value.phoneNumber;
        formModel.receiptAddress = userDeliveryAddress.value.receiptAddress;
        if (userDeliveryAddress.value.receiptName) {
          formRef?.value?.validate();
        }
      }
    }

    watch(userDeliveryAddress, () => {
      refreshAddress();
    });

    onMounted(async () => {
      let res = await api.userShippingAddress();
      console.log(res);
      if (res?.data?.success) {
        userDeliveryAddress.value = res.data.data?.[0];
      }

      refreshAddress();
    });

    function handleConfirm() {
      formRef.value.validate().then(
        async () => {
          submitReg();
        },
        async (va) => {
          if (va.errorFields.length === 0) {
            submitReg();
          }
        }
      );
    }

    async function submitReg() {
      loading.value = true;
      try {
        if (userDeliveryAddress.value?.id) {
          let res = await api.userShippingAddressUpdate({
            ...formModel,
            id: userDeliveryAddress.value?.id,
          });
          loading.value = false;
          if (res.data?.success) {
            message.info(formatServerMsg(res.data.message));
            if (back?.value) {
              router.push({ path: `/grab/${back?.value}` });
            } else {
              router.replace("/profile/user_info");
            }
          } else {
            message.error(formatServerMsg(res.data.message));
          }
        } else {
          let res1 = await api.userShippingAddressAdd(formModel);
          loading.value = false;
          if (res1.data?.success) {
            message.info(formatServerMsg(res1.data.message));
            if (back?.value) {
              router.push({ path: `/grab/${back?.value}` });
            } else {
              router.replace("/profile/user_info");
            }
          } else {
            message.error(formatServerMsg(res1.data.message));
          }
        }
      } finally {
        loading.value = false;
      }
    }

    return {
      t,
      formRef,
      formModel,
      ruleRef,
      validateInfos,
      validateStatus,
      handleConfirm,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-vertical .ant-form-explain {
  padding-bottom: 5px !important;
  //margin-top: -15px !important;
  margin-top: 0 !important;
}

.Sys_submit_btn.address {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
</style>
